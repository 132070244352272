body {
	& .sketch-picker,
	& .chrome-picker {
		background: none !important;
		
		& input {
			width: 100% !important;
			border: 1px solid $input-border-color !important;
			border-radius: $input-border-radius;
			transition: $input-transition;
			box-shadow: none !important;
			text-align: center;
			background: none !important;
			color: var(--#{$prefix}body-color) !important;
		
			&:focus {
				border-color: $input-focus-border-color !important;
				box-shadow: $input-btn-focus-box-shadow !important;
				outline: none !important;
			}
		}
		& .flexbox-fix:last-child {
			border-color: var(--#{$prefix}border-color) !important;
		}
		& label {
			color: var(--#{$prefix}body-color) !important;
		}
	}
	& .dropdown-menu {
		& .sketch-picker,
		& .chrome-picker {
			border: 1px solid var(--#{$prefix}dropdown-border-color);
			border-radius: $dropdown-border-radius !important;
			
			& input:not(:focus),
			& .flexbox-fix:last-child {
				border-color: var(--#{$prefix}dropdown-border-color) !important;
			}
		}
	}
}