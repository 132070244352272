body .rti--container {
	background: transparent;
	border: 1px solid $input-border-color;
	border-radius: $border-radius;
	padding: $input-padding-y - rem(2px) $input-padding-x;
	line-height: 1;
	gap: rem(4px);
	
	&:focus-within {
		border-color: $input-focus-border-color;
		box-shadow: $input-focus-box-shadow;
	}
	& .rti--tag {
		font-size: rem(12px);
		color: var(--#{$prefix}theme-color);
		line-height: rem(15px);
		min-height: rem(20px);
		font-weight: 500;
		border-radius: rem(20px);
		padding: rem(5px) rem(12px);
		background: var(--#{$prefix}theme);
		
		& button {
			margin-top: rem(1px);
			margin-bottom: rem(-1px);
			color: var(--#{$prefix}theme-color);
			
			@if $enable-rtl {
				margin-left: rem(-5px);
				margin-right: rem(5px);
			} @else {
				margin-right: rem(-5px);
				margin-left: rem(5px);
			}
			&:hover {
				color: var(--#{$prefix}theme-color);
				opacity: .5;
			}
		}
	}
	& .rti--input {
		background: none;
		color: var(--#{$prefix}inverse);
		
		&::placeholder {
			color: rgba(var(--#{$prefix}inverse-rgb), .5);
			font-weight: $input-font-weight;
		}
	}
}