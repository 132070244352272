/*
   01. Color system 
   02. Options toggle setting (rtl, shadow, caret, negative margin, color mode)
   03. Variable prefix setting
   04. Body setting
   05. Spacer setting
   06. Font & headings settings
   07. Grid settings
   08. Container padding
   09. Component border settings
   10. Component border-radius settings
   11. Component box-shadow settings 
   12. Component color settings 
   13. Component caret settings 
   14. Component transition settings 
   15. Component ratio settings 
   16. Container padding settings
   17. App - Header settings
   18. App - Sidebar settings
   19. App - Content settings
   20. App - Pos system settings
   21. Link settings
   22. Alerts settings
   23. Breadcrumbs settings
   24. Progress bars settings
   25. Buttons + Forms settings
   26. Buttons settings
   27. Button close settings
   28. Forms settings
   29. Form placeholders settings
   30. Form check settings
   31. Form switch settings
   32. Form input group settings
   33. Form select settings
   34. Form range settings
   35. Form file settings
   36. Form Floating settings
   37. Form validation settings
   38. Cards settings
   39. Tooltips settings
   40. Popovers
   41. Modals settings
   42. Offcanvas
   43. Toasts settings
   44. Accordion settings
   45. Tables settings
   46. Dropdown settings
   47. List group settings
   48. Nav settings
   49. Navbar settings
   50. Pagination settings
   51. Z-index settings
   52. Badges settings
   53. Position settings
   54. Image thumbnails settings
   55. Figures settings
   56. Carousel settings
   57. Spinners settings
   58. Code settings
*/


// 01. Color system 

$white:        #FFFFFF !default;
$gray-500:     #617a86 !default;
$gray-100:     mix(#fff, $gray-500, 60%) !default;
$gray-200:     mix(#fff, $gray-500, 45%) !default;
$gray-300:     mix(#fff, $gray-500, 30%) !default;
$gray-400:     mix(#fff, $gray-500, 15%) !default;
$gray-500:     #617a86 !default;
$gray-600:     mix(#000, $gray-500, 15%) !default;
$gray-700:     mix(#000, $gray-500, 30%) !default;
$gray-800:     mix(#000, $gray-500, 45%) !default;
$gray-900:     mix(#000, $gray-500, 60%) !default;
$yellow:       #f9e80d !default;
$orange:       #ff9f0c !default;
$aqua:         #0cfff5 !default;
$blue:         #0271ff !default;
$indigo:       #5c25ff !default;
$purple:       #b90dff !default;
$red:          #e00000 !default;
$teal:         #249d79 !default;
$green:        #148922 !default;
$lime:         #50b00a !default;
$pink:         #e61079 !default;
$cyan:         #009be3 !default;
$default:      $gray-400 !default;
$dark:         $gray-800 !default;
$black:        #000000 !default;
$light:        $gray-100 !default;
$primary:      $blue !default;
$warning:      $orange !default;
$danger:       $red !default;
$success:      $teal !default;
$info:         $cyan !default;
$secondary:    $gray-400 !default;
$text-muted:   $gray-400 !default;
$inverse:      $black !default;
$theme:        $teal !default;
$theme-color:  $white !default;
$theme-colors: (
	"default":     $default,
	"theme":       $theme,
	"theme-color": $theme-color,
	"primary":     $primary,
	"secondary":   $secondary,
	"warning":     $warning,
	"danger":      $danger,
	"success":     $success,
	"inverse":     $inverse,
	"info":        $info,
	"dark":        $dark,
	"black":       $black,
	"white":       $white,
	"purple":      $purple,
	"indigo":      $indigo,
	"red":         $red,
	"pink":        $pink,
	"green":       $green,
	"yellow":      $yellow,
	"teal":        $teal,
	"muted":       $gray-500,
  "light":       $light,
  "lime":        $lime,
	"gray-100":    $gray-100,
	"gray-200":    $gray-200,
	"gray-300":    $gray-300,
	"gray-400":    $gray-400,
	"gray-500":    $gray-500,
	"gray-600":    $gray-600,
	"gray-700":    $gray-700,
	"gray-800":    $gray-800,
	"gray-900":    $gray-900
);

$min-contrast-ratio:                            3 !default;
$color-contrast-dark:                           $black !default;
$color-contrast-light:                          $white !default;

$blue-100:                                      tint-color($blue, 80%) !default;
$blue-200:                                      tint-color($blue, 60%) !default;
$blue-300:                                      tint-color($blue, 40%) !default;
$blue-400:                                      tint-color($blue, 20%) !default;
$blue-500:                                      $blue !default;
$blue-600:                                      shade-color($blue, 20%) !default;
$blue-700:                                      shade-color($blue, 40%) !default;
$blue-800:                                      shade-color($blue, 60%) !default;
$blue-900:                                      shade-color($blue, 80%) !default;

$indigo-100:                                    tint-color($indigo, 80%) !default;
$indigo-200:                                    tint-color($indigo, 60%) !default;
$indigo-300:                                    tint-color($indigo, 40%) !default;
$indigo-400:                                    tint-color($indigo, 20%) !default;
$indigo-500:                                    $indigo !default;
$indigo-600:                                    shade-color($indigo, 20%) !default;
$indigo-700:                                    shade-color($indigo, 40%) !default;
$indigo-800:                                    shade-color($indigo, 60%) !default;
$indigo-900:                                    shade-color($indigo, 80%) !default;

$purple-100:                                    tint-color($purple, 80%) !default;
$purple-200:                                    tint-color($purple, 60%) !default;
$purple-300:                                    tint-color($purple, 40%) !default;
$purple-400:                                    tint-color($purple, 20%) !default;
$purple-500:                                    $purple !default;
$purple-600:                                    shade-color($purple, 20%) !default;
$purple-700:                                    shade-color($purple, 40%) !default;
$purple-800:                                    shade-color($purple, 60%) !default;
$purple-900:                                    shade-color($purple, 80%) !default;

$pink-100:                                      tint-color($pink, 80%) !default;
$pink-200:                                      tint-color($pink, 60%) !default;
$pink-300:                                      tint-color($pink, 40%) !default;
$pink-400:                                      tint-color($pink, 20%) !default;
$pink-500:                                      $pink !default;
$pink-600:                                      shade-color($pink, 20%) !default;
$pink-700:                                      shade-color($pink, 40%) !default;
$pink-800:                                      shade-color($pink, 60%) !default;
$pink-900:                                      shade-color($pink, 80%) !default;

$red-100:                                       tint-color($red, 80%) !default;
$red-200:                                       tint-color($red, 60%) !default;
$red-300:                                       tint-color($red, 40%) !default;
$red-400:                                       tint-color($red, 20%) !default;
$red-500:                                       $red !default;
$red-600:                                       shade-color($red, 20%) !default;
$red-700:                                       shade-color($red, 40%) !default;
$red-800:                                       shade-color($red, 60%) !default;
$red-900:                                       shade-color($red, 80%) !default;

$orange-100:                                    tint-color($orange, 80%) !default;
$orange-200:                                    tint-color($orange, 60%) !default;
$orange-300:                                    tint-color($orange, 40%) !default;
$orange-400:                                    tint-color($orange, 20%) !default;
$orange-500:                                    $orange !default;
$orange-600:                                    shade-color($orange, 20%) !default;
$orange-700:                                    shade-color($orange, 40%) !default;
$orange-800:                                    shade-color($orange, 60%) !default;
$orange-900:                                    shade-color($orange, 80%) !default;

$yellow-100:                                    tint-color($yellow, 80%) !default;
$yellow-200:                                    tint-color($yellow, 60%) !default;
$yellow-300:                                    tint-color($yellow, 40%) !default;
$yellow-400:                                    tint-color($yellow, 20%) !default;
$yellow-500:                                    $yellow !default;
$yellow-600:                                    shade-color($yellow, 20%) !default;
$yellow-700:                                    shade-color($yellow, 40%) !default;
$yellow-800:                                    shade-color($yellow, 60%) !default;
$yellow-900:                                    shade-color($yellow, 80%) !default;

$green-100:                                     tint-color($green, 80%) !default;
$green-200:                                     tint-color($green, 60%) !default;
$green-300:                                     tint-color($green, 40%) !default;
$green-400:                                     tint-color($green, 20%) !default;
$green-500:                                     $green !default;
$green-600:                                     shade-color($green, 20%) !default;
$green-700:                                     shade-color($green, 40%) !default;
$green-800:                                     shade-color($green, 60%) !default;
$green-900:                                     shade-color($green, 80%) !default;

$teal-100:                                      tint-color($teal, 80%) !default;
$teal-200:                                      tint-color($teal, 60%) !default;
$teal-300:                                      tint-color($teal, 40%) !default;
$teal-400:                                      tint-color($teal, 20%) !default;
$teal-500:                                      $teal !default;
$teal-600:                                      shade-color($teal, 20%) !default;
$teal-700:                                      shade-color($teal, 40%) !default;
$teal-800:                                      shade-color($teal, 60%) !default;
$teal-900:                                      shade-color($teal, 80%) !default;

$cyan-100:                                      tint-color($cyan, 80%) !default;
$cyan-200:                                      tint-color($cyan, 60%) !default;
$cyan-300:                                      tint-color($cyan, 40%) !default;
$cyan-400:                                      tint-color($cyan, 20%) !default;
$cyan-500:                                      $cyan !default;
$cyan-600:                                      shade-color($cyan, 20%) !default;
$cyan-700:                                      shade-color($cyan, 40%) !default;
$cyan-800:                                      shade-color($cyan, 60%) !default;
$cyan-900:                                            shade-color($cyan, 80%) !default;

$primary-text:                                  $blue-600 !default;
$secondary-text:                                $gray-600 !default;
$success-text:                                  $green-600 !default;
$info-text:                                     $cyan-700 !default;
$warning-text:                                  $yellow-700 !default;
$danger-text:                                   $red-600 !default;
$light-text:                                    $gray-600 !default;
$dark-text:                                     $gray-700 !default;

$primary-bg-subtle:                             $blue-100 !default;
$secondary-bg-subtle:                           $gray-100 !default;
$success-bg-subtle:                             $green-100 !default;
$info-bg-subtle:                                $cyan-100 !default;
$warning-bg-subtle:                             $yellow-100 !default;
$danger-bg-subtle:                              $red-100 !default;
$light-bg-subtle:                               mix($gray-100, $white) !default;
$dark-bg-subtle:                                $gray-400 !default;

$primary-border-subtle:                         $blue-200 !default;
$secondary-border-subtle:                       $gray-200 !default;
$success-border-subtle:                         $green-200 !default;
$info-border-subtle:                            $cyan-200 !default;
$warning-border-subtle:                         $yellow-200 !default;
$danger-border-subtle:                          $red-200 !default;
$light-border-subtle:                           $gray-200 !default;
$dark-border-subtle:                            $gray-500 !default;



// 02. Options toggle setting (rtl, shadow, caret, negative margin, color mode)

$enable-rtl:                                    false !default;
$enable-caret:                                  true !default;
$enable-rounded:                                true !default;
$enable-shadows:                                false !default;
$enable-gradients:                              false !default;
$enable-transitions:                            true !default;
$enable-reduced-motion:                         true !default;
$enable-smooth-scroll:                          true !default;
$enable-grid-classes:                           true !default;
$enable-container-classes:                      true !default;
$enable-cssgrid:                                false !default;
$enable-button-pointers:                        true !default;
$enable-rfs:                                    true !default;
$enable-validation-icons:                       true !default;
$enable-negative-margins:                       true !default;
$enable-deprecation-messages:                   true !default;
$enable-important-utilities:                    true !default;
$enable-dark-mode:                              true !default;
$color-mode-type:                               data !default; // `data` or `media-query`
$gradient:                                      linear-gradient(180deg, rgba($white, .15), rgba($white, 0)) !default;



// 03. Variable prefix setting

$prefix:                                        bs- !default;



// 04. Body setting

$body-bg:                                       $white !default;
$body-bg-gradient:                              linear-gradient(180deg, rgba($white,.90) 0%, rgba($white,.99) 100%) !default;
$body-bg-cover:                                 url('images/cover.jpg') !default;
$body-bg-image:                                 url('images/pattern.png') !default;
$body-bg-image-size:                            rem(75px) !default;
$body-letter-spacing:                           rem(.75px) !default;
$body-color:                                    rgba($black, .75) !default;
$body-font-weight:                              400 !default;
$body-emphasis-color:                           $black !default;
$body-secondary-color:                          rgba($body-color, .75) !default;
$body-secondary-bg:                             $gray-100 !default;
$body-tertiary-color:                           rgba($body-color, .5) !default;
$body-tertiary-bg:                              rgba($body-color, .1) !default;
$emphasis-color:                                $black !default;



// 05. Spacer setting

$spacer:                                        rem-default(16px) !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;



// 06. Font & headings settings

$font-family-base:                              'Chakra Petch', sans-serif !default;
$font-size-base:                                .875rem !default;
$font-size-sm:                                  .8125rem !default;
$font-size-lg:                                 	1rem !default;
$font-weight-light:                             300 !default;
$font-weight-semibold:                          500 !default;
$font-weight-bold:                              600 !default;
$font-weight-base:                              400 !default;
$font-weight-normal:                            400 !default;
$headings-font-weight:                          $font-weight-bold !default;
$headings-color:                                $black !default;
$line-height-base:                              1.5 !default;
$line-height-sm:                                1.25 !default;
$line-height-lg:                                2 !default;
$lead-font-size:                                $font-size-base * 1.25 !default;
$lead-font-weight:                              300 !default;
$small-font-size:                               .875em !default;
$sub-sup-font-size:                             .75em !default;
$text-muted:                                    var(--#{$prefix}secondary-color) !default;
$initialism-font-size:                          $small-font-size !default;
$blockquote-margin-y:                           $spacer !default;
$blockquote-font-size:                          $font-size-base * 1.25 !default;
$blockquote-footer-color:                       $gray-600 !default;
$blockquote-footer-font-size:                   $small-font-size !default;
$hr-margin-y:                                   $spacer !default;
$hr-color:                                      inherit !default;
$hr-border-color:                               null !default;
$hr-border-width:                               var(--#{$prefix}border-width) !default;
$hr-opacity:                                    .25 !default;
$legend-margin-bottom:                          .5rem !default;
$legend-font-size:                              1.5rem !default;
$legend-font-weight:                            null !default;
$dt-font-weight:                                $font-weight-bold !default;
$list-inline-padding:                           .5rem !default;
$mark-padding:                                  .1875em !default;
$mark-bg:                                       $yellow-100 !default;
$paragraph-margin-bottom:                       1rem !default;



// 07. Grid settings

$grid-gutter-width:                             20px !default;
$grid-columns:                                  12 !default;
$grid-row-columns:                              6 !default;
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1660px,
  xxxl: 1900px
) !default;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;



// 08. Container padding

$container-padding-x:                           $grid-gutter-width !default;



// 09. Component border settings

$border-width:                                  1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;
$border-style:                                  solid !default;
$border-color:                                  rgba($black, .2) !default;
$border-color-translucent:                      rgba($black, .1) !default;



// 10. Component border-radius settings

$border-radius:                                 4px !default;
$border-radius-lg:                              8px !default;
$border-radius-sm:                              2px !default;
$border-radius-xl:                              1rem !default;
$border-radius-2xl:                             2rem !default;
$border-radius-pill:                            50rem !default;



// 11. Component box-shadow settings 

$box-shadow:                                    0 .5rem 1rem rgba(var(--#{$prefix}inverse-rgb), .075) !default;
$box-shadow-sm:                                 0 .125rem .25rem rgba(var(--#{$prefix}inverse-rgb), .035) !default;
$box-shadow-lg:                                 0 1rem 3rem rgba(var(--#{$prefix}inverse-rgb), .175) !default;
$box-shadow-inset:                              inset 0 1px 2px rgba(var(--#{$prefix}inverse-rgb), .075) !default;



// 12. Component color settings 

$component-bg:                                  $white !default;
$component-color:                               $body-color !default;
$component-active-color:                        $theme-color !default;
$component-active-bg:                           $theme !default;



// 13. Component caret settings 

$caret-width:                                   .3em !default;
$caret-vertical-align:                          $caret-width * .85 !default;
$caret-spacing:                                 $caret-width * .85 !default;



// 14. Component transition settings 

$transition-base:                               all .2s ease-in-out !default;
$transition-fade:                               opacity .15s linear !default;
$transition-collapse:                           height .35s ease !default;
$transition-collapse-width:                     width .35s ease !default;



// 15. Component ratio settings 

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%)
) !default;



// 16. Container padding settings

$container-padding-x:                           $grid-gutter-width !default;



// 17. App - Header settings

$app-header-height:                             rem(52px) !default;
$app-header-bg:                                 $white !default;
$app-header-zindex:                             1020 !default;
$app-header-search-max-width:                   rem(967px) !default;
$app-header-padding-x:                          $spacer * .25 !default;
$app-header-padding-y:                          $spacer * .25 !default;
$app-header-brand-img:                          url('images/logo.png') !default;
$app-header-link-color:                         $black !default;
$app-header-link-hover-color:                   $black !default;
$app-header-scroll-box-shadow:                  null !default;



// 18. App - Sidebar settings
      
$app-sidebar-width:                             rem(270px) !default;
$app-sidebar-menu-header-color:                 rgba(var(--#{$prefix}inverse-rgb), .3) !default;
$app-sidebar-menu-link-color:                   rgba(var(--#{$prefix}inverse-rgb), .5) !default;
$app-sidebar-menu-link-hover-color:             $black !default;
$app-sidebar-menu-link-active-color:            $black !default;
$app-sidebar-menu-link-icon-active-color:       var(--#{$prefix}theme) !default;
$app-sidebar-zindex:                            $app-header-zindex - 10 !default;
$app-sidebar-mobile-zindex:                     $app-header-zindex + 10 !default;
$app-sidebar-mobile-bg:                         rgba(255, 255, 255, .98) !default;
$app-sidebar-mobile-backdrop-zindex:            $app-sidebar-mobile-zindex - 10 !default;
$app-sidebar-mobile-backdrop-bg:                rgba($black, .25) !default;



// 19. App - Top Nav settings
$app-top-nav-bg:                                $white !default;
$app-top-nav-zindex:                            $app-header-zindex - 10 !default;
$app-top-nav-height:                            rem(46px) !default;
$app-top-nav-menu-link-padding-x:               rem(20px) !default;
$app-top-nav-menu-link-padding-y:               rem(10px) !default;
$app-top-nav-menu-link-color:                   rgba(var(--#{$prefix}inverse-rgb), .5) !default;
$app-top-nav-menu-link-hover-color:             $black !default;
$app-top-nav-menu-link-active-color:            $black !default;
$app-top-nav-menu-link-icon-active-color:       var(--#{$prefix}theme) !default;
$app-top-nav-menu-submenu-bg:                   $white !default;



// 19. App - Content settings

$app-content-padding-y:                         $spacer * 2;
$app-content-padding-x:                         $spacer * 2;
$app-content-sm-padding-y:                      $spacer;
$app-content-sm-padding-x:                      $spacer;



// 20. App - Theme panel settings

$app-theme-panel-bg:                            rgba($white, .95) !default;
$app-theme-panel-color:                       	$black !default;



// 20. App - Pos system settings

$pos-booking-header-height:                     rem(52px) !default;
$pos-counter-header-height:                     rem(52px) !default;
$pos-kitchen-header-height:                     rem(52px) !default;
$pos-stock-header-height:                       rem(52px) !default;
$pos-sidebar-menu-width:                        140px !default;
$pos-sidebar-desktop-width:                     360px !default;
$pos-bg:                                        $gray-200 !default;
$pos-bg-dark-mode:                              shade-color($gray-900, 30%) !default;



// 21. Link settings

$link-color:                                    $theme !default;
$link-hover-color:                              rgba($theme, .5) !default;
$link-decoration:                               underline !default;
$link-shade-percentage:                         20% !default;
$link-hover-decoration:                         null !default;
$stretched-link-pseudo-element:                 after !default;
$stretched-link-z-index:                        1 !default;



// 22. Alerts settings

$alert-padding-y:                               $spacer !default;
$alert-padding-x:                               $spacer !default;
$alert-margin-bottom:                           1rem !default;
$alert-border-radius:                           $border-radius !default;
$alert-link-font-weight:                        $font-weight-bold !default;
$alert-border-width:                            var(--#{$prefix}border-width) !default;
$alert-dismissible-padding-r:                   $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side



// 23. Breadcrumbs settings

$breadcrumb-font-size:                          85% !default;
$breadcrumb-padding-y:                          0 !default;
$breadcrumb-padding-x:                          0 !default;
$breadcrumb-item-padding-x:                     .5rem !default;
$breadcrumb-margin-bottom:                      1rem !default;
$breadcrumb-bg:                                 null !default;
$breadcrumb-divider-color:                      var(--#{$prefix}secondary-color) !default;
$breadcrumb-active-color:                       var(--#{$prefix}secondary-color) !default;
$breadcrumb-divider:                            quote("/") !default;
$breadcrumb-divider-flipped:                    $breadcrumb-divider !default;
$breadcrumb-border-radius:                      null !default;



// 24. Progress bars settings

$progress-height:                               1rem !default;
$progress-font-size:                            $font-size-base * .75 !default;
$progress-bg:                                   rgba(var(--#{$prefix}inverse-rgb), .15) !default;
$progress-border-radius:                        var(--#{$prefix}border-radius) !default;
$progress-box-shadow:                           var(--#{$prefix}box-shadow-inset) !default;
$progress-bar-color:                            $white !default;
$progress-bar-bg:                               $primary !default;
$progress-bar-animation-timing:                 1s linear infinite !default;
$progress-bar-transition:                       width .6s ease !default;



// 25. Buttons + Forms settings

$input-btn-padding-y:                           .375rem !default;
$input-btn-padding-x:                           .75rem !default;
$input-btn-font-family:                         null !default;
$input-btn-font-size:                           $font-size-base !default;
$input-btn-line-height:                         $line-height-base !default;
$input-btn-focus-width:                         .25rem !default;
$input-btn-focus-color-opacity:                 .25 !default;
$input-btn-focus-color:                         rgba(var(--#{$prefix}inverse-rgb), $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:                          0 !default;
$input-btn-focus-box-shadow:                    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;
$input-btn-padding-y-sm:                        .25rem !default;
$input-btn-padding-x-sm:                        .5rem !default;
$input-btn-font-size-sm:                        $font-size-sm !default;
$input-btn-padding-y-lg:                        .5rem !default;
$input-btn-padding-x-lg:                        1rem !default;
$input-btn-font-size-lg:                        $font-size-lg !default;
$input-btn-border-width:                        var(--#{$prefix}border-width) !default;



// 26. Buttons settings

$btn-padding-y:                                 $input-btn-padding-y !default;
$btn-padding-x:                                 $input-btn-padding-x !default;
$btn-font-family:                               $input-btn-font-family !default;
$btn-font-size:                                 $input-btn-font-size !default;
$btn-line-height:                               $input-btn-line-height !default;
$btn-white-space:                               null !default; // Set to `nowrap` to prevent text wrapping
$btn-padding-y-sm:                              $input-btn-padding-y-sm !default;
$btn-padding-x-sm:                              $input-btn-padding-x-sm !default;
$btn-font-size-sm:                              $input-btn-font-size-sm !default;
$btn-padding-y-lg:                              $input-btn-padding-y-lg !default;
$btn-padding-x-lg:                              $input-btn-padding-x-lg !default;
$btn-font-size-lg:                              $input-btn-font-size-lg !default;
$btn-border-width:                              $input-btn-border-width !default;
$btn-font-weight:                               $font-weight-normal !default;
$btn-box-shadow:                                inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:                               $input-btn-focus-width !default;
$btn-focus-box-shadow:                          $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:                          .65 !default;
$btn-active-box-shadow:                         inset 0 3px 5px rgba($black, .125) !default;
$btn-link-color:                                var(--#{$prefix}link-color) !default;
$btn-link-hover-color:                          var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color:                       $gray-600 !default;
$btn-border-radius:                             $border-radius !default;
$btn-border-radius-sm:                          $border-radius-sm !default;
$btn-border-radius-lg:                          $border-radius-lg !default;
$btn-transition:                                color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$btn-hover-bg-shade-amount:                     15% !default;
$btn-hover-bg-tint-amount:                      15% !default;
$btn-hover-border-shade-amount:                 20% !default;
$btn-hover-border-tint-amount:                  10% !default;
$btn-active-bg-shade-amount:                    20% !default;
$btn-active-bg-tint-amount:                     20% !default;
$btn-active-border-shade-amount:                25% !default;
$btn-active-border-tint-amount:                 10% !default;



// 27. Button close settings

$btn-close-width:                               1em !default;
$btn-close-height:                              $btn-close-width !default;
$btn-close-padding-x:                           .25em !default;
$btn-close-padding-y:                           $btn-close-padding-x !default;
$btn-close-color:                               $black !default;
$btn-close-bg:                                  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;
$btn-close-focus-shadow:                        $input-btn-focus-box-shadow !default;
$btn-close-opacity:                             .5 !default;
$btn-close-hover-opacity:                       .75 !default;
$btn-close-focus-opacity:                       1 !default;
$btn-close-disabled-opacity:                    .25 !default;
$btn-close-white-filter:                        invert(1) grayscale(100%) brightness(200%) !default;



// 28. Forms settings

$form-text-margin-top:                          .25rem !default;
$form-text-font-size:                           $small-font-size !default;
$form-text-font-style:                          null !default;
$form-text-font-weight:                         null !default;
$form-text-color:                               $text-muted !default;
$form-label-margin-bottom:                      .5rem !default;
$form-label-font-size:                          null !default;
$form-label-font-style:                         null !default;
$form-label-font-weight:                        null !default;
$form-label-color:                              null !default;
$input-padding-y:                               $input-btn-padding-y !default;
$input-padding-x:                               $input-btn-padding-x !default;
$input-font-family:                             $input-btn-font-family !default;
$input-font-size:                               $input-btn-font-size !default;
$input-font-weight:                             $font-weight-base !default;
$input-line-height:                             $input-btn-line-height !default;
$input-padding-y-sm:                            $input-btn-padding-y-sm !default;
$input-padding-x-sm:                            $input-btn-padding-x-sm !default;
$input-font-size-sm:                            $input-btn-font-size-sm !default;
$input-padding-y-lg:                            $input-btn-padding-y-lg !default;
$input-padding-x-lg:                            $input-btn-padding-x-lg !default;
$input-font-size-lg:                            $input-btn-font-size-lg !default;
$input-bg:                                      transparent !default;
$input-disabled-color:                          null !default;
$input-disabled-bg:                             var(--#{$prefix}form-control-disabled-bg) !default;
$input-disabled-border-color:                   null !default;
$input-color:                                   var(--#{$prefix}body-color) !default;
$input-border-color:                            var(--#{$prefix}border-color) !default; //$gray-400
$input-border-width:                            $input-btn-border-width !default;
$input-box-shadow:                              $box-shadow-inset !default;
$input-border-radius:                           $border-radius !default;
$input-border-radius-sm:                        $border-radius-sm !default;
$input-border-radius-lg:                        $border-radius-lg !default;
$input-focus-bg:                                $input-bg !default;
$input-focus-border-color:                      rgba(var(--#{$prefix}inverse-rgb), .5) !default;
$input-focus-color:                             $input-color !default;
$input-focus-width:                             $input-btn-focus-width !default;
$input-focus-box-shadow:                        $input-btn-focus-box-shadow !default;
$input-placeholder-color:                       rgba(var(--#{$prefix}body-color-rgb), .5) !default;
$input-plaintext-color:                         var(--#{$prefix}body-color) !default;
$input-height-border:                           calc($input-border-width * 2) !default; // stylelint-disable-line function-disallowed-list
$input-height-inner:                            add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:                       add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:                    add($input-line-height * .25em, $input-padding-y * .5) !default;
$input-height:                                  add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:                               add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                               add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;
$input-transition:                              border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$form-color-width:                              3rem !default;



// 29. Form placeholders settings

$placeholder-opacity-max:                       .5 !default;
$placeholder-opacity-min:                       .2 !default;



// 30. Form check settings

$form-check-input-width:                        1.3em !default;
$form-check-min-height:                         1.3em !default;
$form-check-padding-start:                      $form-check-input-width + .5em !default;
$form-check-margin-bottom:                      .125rem !default;
$form-check-label-color:                        null !default;
$form-check-label-cursor:                       null !default;
$form-check-transition:                         null !default;
$form-check-input-active-filter:                brightness(90%) !default;
$form-check-input-bg:                           $input-bg !default;
$form-check-input-border:                       var(--#{$prefix}border-width) solid var(--#{$prefix}border-color) !default;
$form-check-input-border-radius:                .25em !default;
$form-check-radio-border-radius:                50% !default;
$form-check-input-focus-border:                 $input-focus-border-color !default;
$form-check-input-focus-box-shadow:             $input-btn-focus-box-shadow !default;
$form-check-input-checked-color:                $component-active-color !default;
$form-check-input-checked-bg-color:             $component-active-bg !default;
$form-check-input-checked-border-color:         $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
$form-check-radio-checked-bg-image:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;
$form-check-input-indeterminate-color:          $component-active-color !default;
$form-check-input-indeterminate-bg-color:       $component-active-bg !default;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;
$form-check-input-disabled-opacity:             .5 !default;
$form-check-label-disabled-opacity:             $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity:         $btn-disabled-opacity !default;
$form-check-inline-margin-end:                  1rem !default;



// 31. Form switch settings

$form-switch-color:                             rgba($black, .25) !default;
$form-switch-width:                             2.3em !default;
$form-switch-padding-start:                     $form-switch-width + .5em !default;
$form-switch-bg-image:                          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius:                     $form-switch-width !default;
$form-switch-transition:                        background-position .15s ease-in-out !default;
$form-switch-focus-color:                       tint-color($black, 50%) !default;
$form-switch-focus-bg-image:                    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;
$form-switch-checked-color:                     $component-active-color !default;
$form-switch-checked-bg-image:                  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position:               right center !default;



// 32. Form input group settings

$input-group-addon-padding-y:                   $input-padding-y !default;
$input-group-addon-padding-x:                   $input-padding-x !default;
$input-group-addon-font-weight:                 $input-font-weight !default;
$input-group-addon-color:                       $input-color !default;
$input-group-addon-bg:                          var(--#{$prefix}tertiary-bg) !default;
$input-group-addon-border-color:                $input-border-color !default;



// 33. Form select settings

$form-select-padding-y:                         $input-padding-y !default;
$form-select-padding-x:                         $input-padding-x !default;
$form-select-font-family:                       $input-font-family !default;
$form-select-font-size:                         $input-font-size !default;
$form-select-indicator-padding:                 $form-select-padding-x * 3 !default; // Extra padding for background-image
$form-select-font-weight:                       $input-font-weight !default;
$form-select-line-height:                       $input-line-height !default;
$form-select-color:                             $input-color !default;
$form-select-bg:                                $input-bg !default;
$form-select-disabled-color:                    null !default;
$form-select-disabled-bg:                       $input-disabled-bg !default;
$form-select-disabled-border-color:             $input-disabled-border-color !default;
$form-select-bg-position:                       right $form-select-padding-x center !default;
$form-select-bg-size:                           16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color:                   $gray-800 !default;
$form-select-indicator:                         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;
$form-select-feedback-icon-padding-end:         $form-select-padding-x * 2.5 + $form-select-indicator-padding !default;
$form-select-feedback-icon-position:            center right $form-select-indicator-padding !default;
$form-select-feedback-icon-size:                $input-height-inner-half $input-height-inner-half !default;
$form-select-border-width:                      $input-border-width !default;
$form-select-border-color:                      $input-border-color !default;
$form-select-border-radius:                     $input-border-radius !default;
$form-select-box-shadow:                        $box-shadow-inset !default;
$form-select-focus-border-color:                $input-focus-border-color !default;
$form-select-focus-width:                       $input-focus-width !default;
$form-select-focus-box-shadow:                  0 0 0 $form-select-focus-width $input-btn-focus-color !default;
$form-select-padding-y-sm:                      $input-padding-y-sm !default;
$form-select-padding-x-sm:                      $input-padding-x-sm !default;
$form-select-font-size-sm:                      $input-font-size-sm !default;
$form-select-border-radius-sm:                  $input-border-radius-sm !default;
$form-select-padding-y-lg:                      $input-padding-y-lg !default;
$form-select-padding-x-lg:                      $input-padding-x-lg !default;
$form-select-font-size-lg:                      $input-font-size-lg !default;
$form-select-border-radius-lg:                  $input-border-radius-lg !default;
$form-select-transition:                        $input-transition !default;



// 34. Form range settings

$form-range-track-width:                        100% !default;
$form-range-track-height:                       .5rem !default;
$form-range-track-cursor:                       pointer !default;
$form-range-track-bg:                           rgba(var(--#{$prefix}inverse-rgb), .1) !default;
$form-range-track-border-radius:                1rem !default;
$form-range-track-box-shadow:                   $box-shadow-inset !default;
$form-range-thumb-width:                        1rem !default;
$form-range-thumb-height:                       $form-range-thumb-width !default;
$form-range-thumb-bg:                           var(--#{$prefix}theme) !default;
$form-range-thumb-border:                       0 !default;
$form-range-thumb-border-radius:                1rem !default;
$form-range-thumb-box-shadow:                   0 .1rem .25rem rgba($black, .1) !default;
$form-range-thumb-focus-box-shadow:             0 0 0 1px transparent, $input-focus-box-shadow !default;
$form-range-thumb-focus-box-shadow-width:       $input-focus-width !default; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:                    rgba(var(--#{$prefix}theme-rgb), .75) !default;
$form-range-thumb-disabled-bg:                  var(--#{$prefix}secondary-color) !default;
$form-range-thumb-transition:                   background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;



// 35. Form file settings

$form-file-button-color:                        $input-color !default;
$form-file-button-bg:                           var(--#{$prefix}tertiary-bg) !default;
$form-file-button-hover-bg:                     var(--#{$prefix}border-color) !default;



// 36. Form Floating settings

$form-floating-height:                          add(3.5rem, $input-height-border) !default;
$form-floating-line-height:                     1.25 !default;
$form-floating-padding-x:                       $input-padding-x !default;
$form-floating-padding-y:                       1rem !default;
$form-floating-input-padding-t:                 1.625rem !default;
$form-floating-input-padding-b:                 .625rem !default;
$form-floating-label-height:                    1.875em !default;
$form-floating-label-opacity:                   .65 !default;
$form-floating-label-transform:                 scale(.85) translateY(-.5rem) translateX(.15rem) !default;
$form-floating-label-disabled-color:            $gray-600 !default;
$form-floating-transition:                      opacity .1s ease-in-out, transform .1s ease-in-out !default;



// 37. Form validation settings

$form-feedback-margin-top:                      $form-text-margin-top !default;
$form-feedback-font-size:                       $form-text-font-size !default;
$form-feedback-font-style:                      $form-text-font-style !default;
$form-feedback-valid-color:                     $success !default;
$form-feedback-invalid-color:                   $danger !default;
$form-feedback-icon-valid-color:                $form-feedback-valid-color !default;
$form-feedback-icon-valid:                      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color:              $form-feedback-invalid-color !default;
$form-feedback-icon-invalid:                    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
$form-validation-states: (
  "valid": (
    "color":            var(--#{$prefix}success-text),
    "icon":             $form-feedback-icon-valid,
    "tooltip-color":    #fff,
    "tooltip-bg-color": var(--#{$prefix}success),
    "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}success-rgb), $input-btn-focus-color-opacity),
    "border-color":     var(--#{$prefix}success),
  ),
  "invalid": (
    "color":            var(--#{$prefix}danger-text),
    "icon":             $form-feedback-icon-invalid,
    "tooltip-color":    #fff,
    "tooltip-bg-color": var(--#{$prefix}danger),
    "focus-box-shadow": 0 0 $input-btn-focus-blur $input-focus-width rgba(var(--#{$prefix}danger-rgb), $input-btn-focus-color-opacity),
    "border-color":     var(--#{$prefix}danger),
  )
) !default;



// 38. Cards settings

$card-spacer-y:                                 $spacer !default;
$card-spacer-x:                                 $spacer !default;
$card-title-spacer-y:                           $spacer * .5 !default;
$card-title-color:                              null !default;
$card-subtitle-color:                           null !default;
$card-border-width:                             var(--#{$prefix}border-width) !default;
$card-border-color:                             var(--#{$prefix}border-color-translucent) !default;
$card-border-radius:                            var(--#{$prefix}border-radius) !default;
$card-box-shadow:                               null !default;
$card-inner-border-radius:                      subtract($card-border-radius, $card-border-width) !default;
$card-cap-padding-y:                            $card-spacer-y * .5 !default;
$card-cap-padding-x:                            $card-spacer-x !default;
$card-cap-bg:                                   rgba(var(--#{$prefix}body-color-rgb), .03) !default;
$card-cap-color:                                null !default;
$card-height:                                   null !default;
$card-color:                                    null !default;
$card-bg:                                       transparent !default;
$card-img-overlay-padding:                      $spacer !default;
$card-group-margin:                             $grid-gutter-width * .5 !default;



// 39. Tooltips settings

$tooltip-font-size:                             $font-size-sm !default;
$tooltip-max-width:                             200px !default;
$tooltip-color:                                 var(--#{$prefix}body-bg) !default;
$tooltip-bg:                                    var(--#{$prefix}inverse) !default;
$tooltip-border-radius:                         var(--#{$prefix}border-radius) !default;
$tooltip-opacity:                               .9 !default;
$tooltip-padding-y:                             $spacer * .25 !default;
$tooltip-padding-x:                             $spacer * .5 !default;
$tooltip-arrow-width:                           .8rem !default;
$tooltip-arrow-height:                          .4rem !default;
$form-feedback-tooltip-padding-y:               $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x:               $tooltip-padding-x !default;
$form-feedback-tooltip-font-size:               $tooltip-font-size !default;
$form-feedback-tooltip-line-height:             null !default;
$form-feedback-tooltip-opacity:                 $tooltip-opacity !default;
$form-feedback-tooltip-border-radius:           $tooltip-border-radius !default;



// 40. Popovers

$popover-font-size:                             $font-size-sm !default;
$popover-bg:                                    var(--#{$prefix}body-bg) !default;
$popover-max-width:                             276px !default;
$popover-border-width:                          var(--#{$prefix}border-width) !default;
$popover-border-color:                          var(--#{$prefix}border-color-translucent) !default;
$popover-border-radius:                         var(--#{$prefix}border-radius-lg) !default;
$popover-inner-border-radius:                   calc($popover-border-radius - $popover-border-width) !default; // stylelint-disable-line function-disallowed-list
$popover-box-shadow:                            $box-shadow !default;
$popover-header-font-size:                      $font-size-base !default;
$popover-header-bg:                             var(--#{$prefix}secondary-bg) !default;
$popover-header-color:                          $headings-color !default;
$popover-header-padding-y:                      .5rem !default;
$popover-header-padding-x:                      $spacer !default;
$popover-body-color:                            var(--#{$prefix}body-color) !default;
$popover-body-padding-y:                        $spacer !default;
$popover-body-padding-x:                        $spacer !default;
$popover-arrow-width:                           1rem !default;
$popover-arrow-height:                          .5rem !default;



// 41. Modals settings

$modal-inner-padding:                           $spacer !default;
$modal-footer-margin-between:                   .5rem !default;
$modal-dialog-margin:                           .5rem !default;
$modal-dialog-margin-y-sm-up:                   1.75rem !default;
$modal-title-line-height:                       $line-height-base !default;
$modal-content-color:                           null !default;
$modal-content-bg:                              var(--#{$prefix}app-header-bg) !default;
$modal-content-border-color:                    var(--#{$prefix}border-color-translucent) !default;
$modal-content-border-width:                    var(--#{$prefix}border-width) !default;
$modal-content-border-radius:                   0 !default;
$modal-content-inner-border-radius:             0 !default;
$modal-content-box-shadow-xs:                   $box-shadow-sm !default;
$modal-content-box-shadow-sm-up:                $box-shadow !default;
$modal-backdrop-bg:                             #e6e6e6 !default;
$modal-backdrop-opacity:                        .95 !default;
$modal-header-border-color:                     var(--#{$prefix}border-color) !default;
$modal-header-border-width:                     $modal-content-border-width !default;
$modal-header-padding-y:                        $modal-inner-padding !default;
$modal-header-padding-x:                        $modal-inner-padding !default;
$modal-header-padding:                          $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility
$modal-footer-bg:                               null !default;
$modal-footer-border-color:                     $modal-header-border-color !default;
$modal-footer-border-width:                     $modal-header-border-width !default;
$modal-sm:                                      300px !default;
$modal-md:                                      500px !default;
$modal-lg:                                      800px !default;
$modal-xl:                                      1140px !default;
$modal-fade-transform:                          translate(0, -50px) !default;
$modal-show-transform:                          none !default;
$modal-transition:                              transform .3s ease-out !default;
$modal-scale-transform:                         scale(1.02) !default;



// 42. Offcanvas

$offcanvas-padding-y:                           $modal-inner-padding !default;
$offcanvas-padding-x:                           $modal-inner-padding !default;
$offcanvas-horizontal-width:                    400px !default;
$offcanvas-vertical-height:                     30vh !default;
$offcanvas-transition-duration:                 .3s !default;
$offcanvas-border-color:                        $modal-content-border-color !default;
$offcanvas-border-width:                        $modal-content-border-width !default;
$offcanvas-title-line-height:                   $modal-title-line-height !default;
$offcanvas-bg-color:                            var(--#{$prefix}body-bg) !default;
$offcanvas-color:                               var(--#{$prefix}body-color) !default;
$offcanvas-box-shadow:                          $modal-content-box-shadow-xs !default;
$offcanvas-backdrop-bg:                         $modal-backdrop-bg !default;
$offcanvas-backdrop-opacity:                    $modal-backdrop-opacity !default;



// 43. Toasts settings

$toast-max-width:                               350px !default;
$toast-padding-x:                               .75rem !default;
$toast-padding-y:                               .5rem !default;
$toast-font-size:                               .875rem !default;
$toast-color:                                   null !default;
$toast-background-color:                        rgba(var(--#{$prefix}body-bg-rgb), .85) !default;
$toast-border-width:                            var(--#{$prefix}border-width) !default;
$toast-border-color:                            var(--#{$prefix}border-color-translucent) !default;
$toast-border-radius:                           var(--#{$prefix}border-radius) !default;
$toast-box-shadow:                              var(--#{$prefix}box-shadow) !default;
$toast-spacing:                                 $container-padding-x !default;
$toast-header-color:                            var(--#{$prefix}secondary-color) !default;
$toast-header-background-color:                 rgba(var(--#{$prefix}body-bg-rgb), .85) !default;
$toast-header-border-color:                     $toast-border-color !default;



// 44. Accordion settings

$accordion-padding-y:                           1rem !default;
$accordion-padding-x:                           1.25rem !default;
$accordion-color:                               var(--#{$prefix}body-color) !default; // Sass variable because of $accordion-button-icon
$accordion-bg:                                  var(--#{$prefix}body-bg) !default;
$accordion-border-width:                        var(--#{$prefix}border-width) !default;
$accordion-border-color:                        var(--#{$prefix}border-color) !default;
$accordion-border-radius:                       var(--#{$prefix}border-radius) !default;
$accordion-inner-border-radius:                 subtract($accordion-border-radius, $accordion-border-width) !default;
$accordion-body-padding-y:                      $accordion-padding-y !default;
$accordion-body-padding-x:                      $accordion-padding-x !default;
$accordion-button-padding-y:                    $accordion-padding-y !default;
$accordion-button-padding-x:                    $accordion-padding-x !default;
$accordion-button-color:                        var(--#{$prefix}body-color) !default;
$accordion-button-bg:                           var(--#{$prefix}accordion-bg) !default;
$accordion-transition:                          $btn-transition, border-radius .15s ease !default;
$accordion-button-active-bg:                    rgba(var(--#{$prefix}theme-rgb), .15) !default;
$accordion-button-active-color:                 var(--#{$prefix}inverse) !default;
$accordion-button-focus-border-color:           $input-focus-border-color !default;
$accordion-button-focus-box-shadow:             $btn-focus-box-shadow !default;
$accordion-icon-width:                          1.25rem !default;
$accordion-icon-color:                          $body-color !default;
$accordion-icon-active-color:                   $body-color !default;
$accordion-icon-transition:                     transform .2s ease-in-out !default;
$accordion-icon-transform:                      rotate(-180deg) !default;
$accordion-button-icon:                         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
$accordion-button-active-icon:                  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;



// 45. Tables settings

$table-cell-padding-y:                          .5rem !default;
$table-cell-padding-x:                          .5rem !default;
$table-cell-padding-y-sm:                       .25rem !default;
$table-cell-padding-x-sm:                       .25rem !default;
$table-cell-vertical-align:                     top !default;
$table-color:                                   var(--#{$prefix}body-color) !default;
$table-bg:                                      transparent !default;
$table-accent-bg:                               transparent !default;
$table-th-font-weight:                          null !default;
$table-striped-color:                           $table-color !default;
$table-striped-bg-factor:                       .1 !default;
$table-striped-bg:                              rgba(var(--#{$prefix}inverse-rgb), $table-striped-bg-factor) !default;
$table-active-color:                            $table-color !default;
$table-active-bg-factor:                        .1 !default;
$table-active-bg:                               rgba(var(--#{$prefix}inverse-rgb), $table-active-bg-factor) !default;
$table-hover-color:                             $table-color !default;
$table-hover-bg-factor:                         .075 !default;
$table-hover-bg:                                rgba(var(--#{$prefix}inverse-rgb), $table-hover-bg-factor) !default;
$table-border-factor:                           .1 !default;
$table-border-width:                            var(--#{$prefix}border-width) !default;
$table-border-color:                            var(--#{$prefix}border-color) !default;
$table-striped-order:                           odd !default;
$table-striped-columns-order:                   even !default;
$table-group-separator-color:                   currentcolor !default;
$table-caption-color:                           $text-muted !default;
$table-bg-scale:                                -80% !default;
$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
  "success":    shift-color($success, $table-bg-scale),
  "info":       shift-color($info, $table-bg-scale),
  "warning":    shift-color($warning, $table-bg-scale),
  "danger":     shift-color($danger, $table-bg-scale),
  "light":      $light,
  "dark":       $dark,
) !default;



// 46. Dropdown settings

$dropdown-min-width:                            10rem !default;
$dropdown-padding-x:                            0 !default;
$dropdown-padding-y:                            .5rem !default;
$dropdown-spacer:                               .125rem !default;
$dropdown-font-size:                            $font-size-base !default;
$dropdown-color:                                var(--#{$prefix}body-color) !default;
$dropdown-bg:                                   rgba(var(--#{$prefix}body-bg-rgb), .98) !default;
$dropdown-border-color:                         var(--#{$prefix}border-color-translucent) !default;
$dropdown-border-radius:                        0 !default;
$dropdown-border-width:                         var(--#{$prefix}border-width) !default;
$dropdown-inner-border-radius:                  calc($dropdown-border-radius - $dropdown-border-width) !default; // stylelint-disable-line function-disallowed-list
$dropdown-divider-bg:                           $dropdown-border-color !default;
$dropdown-divider-margin-y:                     $spacer * .5 !default;
$dropdown-box-shadow:                           $box-shadow !default;
$dropdown-link-color:                           var(--#{$prefix}body-color) !default;
$dropdown-link-hover-color:                     $dropdown-link-color !default;
$dropdown-link-hover-bg:                        rgba(var(--#{$prefix}body-color-rgb), .15) !default;
$dropdown-link-active-color:                    $component-active-color !default;
$dropdown-link-active-bg:                       $component-active-bg !default;
$dropdown-link-disabled-color:                  $gray-500 !default;
$dropdown-item-padding-y:                       rem(6px) !default;
$dropdown-item-padding-x:                       $spacer !default;
$dropdown-header-color:                         rgba(var(--#{$prefix}body-color-rgb), .5) !default;
$dropdown-header-padding-x:                     $dropdown-item-padding-x !default;
$dropdown-header-padding-y:                     $dropdown-padding-y !default;



// 47. List group settings

$list-group-color:                              var(--#{$prefix}body-color) !default;
$list-group-bg:                                 transparent !default;
$list-group-border-color:                       var(--#{$prefix}border-color) !default;
$list-group-border-width:                       var(--#{$prefix}border-width) !default;
$list-group-border-radius:                      var(--#{$prefix}border-radius) !default;
$list-group-item-padding-y:                     $spacer * .5 !default;
$list-group-item-padding-x:                     $spacer !default;
$list-group-hover-bg:                           var(--#{$prefix}tertiary-bg) !default;
$list-group-active-color:                       $component-active-color !default;
$list-group-active-bg:                          $component-active-bg !default;
$list-group-active-border-color:                $list-group-active-bg !default;
$list-group-disabled-color:                     var(--#{$prefix}secondary-color) !default;
$list-group-disabled-bg:                        $list-group-bg !default;
$list-group-action-color:                       var(--#{$prefix}secondary-color) !default;
$list-group-action-hover-color:                 var(--#{$prefix}inverse) !default;
$list-group-action-active-color:                var(--#{$prefix}body-color) !default;
$list-group-action-active-bg:                   var(--#{$prefix}secondary-bg) !default;



// 48. Nav settings

$nav-link-padding-y:                            .5rem !default;
$nav-link-padding-x:                            1rem !default;
$nav-link-font-size:                            null !default;
$nav-link-font-weight:                          null !default;
$nav-link-color:                                var(--#{$prefix}link-color) !default;
$nav-link-hover-color:                          var(--#{$prefix}link-hover-color) !default;
$nav-link-transition:                           color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
$nav-link-disabled-color:                       var(--#{$prefix}secondary-color) !default;
$nav-tabs-border-color:                         var(--#{$prefix}border-color) !default;
$nav-tabs-border-width:                         var(--#{$prefix}border-width) !default;
$nav-tabs-border-radius:                        var(--#{$prefix}border-radius) !default;
$nav-tabs-link-hover-border-color:              var(--#{$prefix}secondary-bg) var(--#{$prefix}secondary-bg) $nav-tabs-border-color !default;
$nav-tabs-link-active-color:                    var(--#{$prefix}inverse) !default;
$nav-tabs-link-active-bg:                       transparent !default;
$nav-tabs-link-active-border-color:             var(--#{$prefix}border-color) var(--#{$prefix}border-color) $nav-tabs-link-active-bg !default;
$nav-pills-border-radius:                       $border-radius !default;
$nav-pills-link-active-color:                   $component-active-color !default;
$nav-pills-link-active-bg:                      $component-active-bg !default;



// 49. Navbar settings

$navbar-padding-y:                              $spacer * .5 !default;
$navbar-padding-x:                              null !default;
$navbar-nav-link-padding-x:                     .5rem !default;
$navbar-brand-font-size:                        $font-size-lg !default;
$nav-link-height:                               $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:                           $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:                        ($nav-link-height - $navbar-brand-height) * .5 !default;
$navbar-brand-margin-end:                       1rem !default;
$navbar-toggler-padding-y:                      .25rem !default;
$navbar-toggler-padding-x:                      .75rem !default;
$navbar-toggler-font-size:                      $font-size-lg !default;
$navbar-toggler-border-radius:                  $btn-border-radius !default;
$navbar-toggler-focus-width:                    $btn-focus-width !default;
$navbar-toggler-transition:                     box-shadow .15s ease-in-out !default;
$navbar-light-color:                            rgba(var(--#{$prefix}inverse-rgb), .65) !default;
$navbar-light-hover-color:                      rgba(var(--#{$prefix}inverse-rgb), .8) !default;
$navbar-light-active-color:                     rgba(var(--#{$prefix}inverse-rgb), 1) !default;
$navbar-light-disabled-color:                   rgba(var(--#{$prefix}inverse-rgb), .3) !default;
$navbar-light-toggler-icon-bg:                  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{rgba($body-color, .75)}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color:             rgba(var(--#{$prefix}inverse-rgb), .15) !default;
$navbar-light-brand-color:                      $navbar-light-active-color !default;
$navbar-light-brand-hover-color:                $navbar-light-active-color !default;



// 50. Pagination settings

$pagination-padding-y:                          .375rem !default;
$pagination-padding-x:                          .75rem !default;
$pagination-padding-y-sm:                       .25rem !default;
$pagination-padding-x-sm:                       .5rem !default;
$pagination-padding-y-lg:                       .75rem !default;
$pagination-padding-x-lg:                       1.5rem !default;
$pagination-font-size:                          $font-size-base !default;
$pagination-color:                              var(--#{$prefix}inverse) !default;
$pagination-bg:                                 transparent !default;
$pagination-border-radius:                      var(--#{$prefix}border-radius) !default;
$pagination-border-width:                       var(--#{$prefix}border-width) !default;
$pagination-margin-start:                       calc($pagination-border-width * -1) !default; // stylelint-disable-line function-disallowed-list
$pagination-border-color:                       var(--#{$prefix}border-color) !default;
$pagination-focus-color:                        var(--#{$prefix}link-hover-color) !default;
$pagination-focus-bg:                           var(--#{$prefix}secondary-bg) !default;
$pagination-focus-box-shadow:                   $input-btn-focus-box-shadow !default;
$pagination-focus-outline:                      0 !default;
$pagination-hover-color:                        var(--#{$prefix}inverse) !default;
$pagination-hover-bg:                           rgba(var(--#{$prefix}inverse-rgb), .1) !default;
$pagination-hover-border-color:                 var(--#{$prefix}border-color) !default; // Todo in v6: remove this?
$pagination-active-color:                       var(--#{$prefix}theme-color) !default;
$pagination-active-bg:                          var(--#{$prefix}theme) !default;
$pagination-active-border-color:                var(--#{$prefix}theme) !default;
$pagination-disabled-color:                     rgba(var(--#{$prefix}inverse-rgb), .35) !default;
$pagination-disabled-bg:                        rgba(var(--#{$prefix}inverse-rgb), .1) !default;
$pagination-disabled-border-color:              var(--#{$prefix}border-color) !default;
$pagination-transition:                         color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$pagination-border-radius-sm:                   $border-radius-sm !default;
$pagination-border-radius-lg:                   $border-radius-lg !default;



// 51. Z-index settings

$zindex-dropdown:                               1000 !default;
$zindex-sticky:                                 1020 !default;
$zindex-fixed:                                  1030 !default;
$zindex-offcanvas-backdrop:                     1040 !default;
$zindex-offcanvas:                              1045 !default;
$zindex-modal-backdrop:                         1050 !default;
$zindex-modal:                                  1055 !default;
$zindex-popover:                                1070 !default;
$zindex-tooltip:                                1130 !default;
$zindex-toast:                                  1090 !default;
$zindex-levels: (
  n1: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3
) !default;



// 52. Badges settings

$badge-font-size:                               .75em !default;
$badge-font-weight:                             $font-weight-bold !default;
$badge-color:                                   $white !default;
$badge-padding-y:                               .35em !default;
$badge-padding-x:                               .65em !default;
$badge-border-radius:                           $border-radius !default;



// 53. Position settings

$position-values: (
  0: 0,
  50: 50%,
  100: 100%
) !default;



// 54. Image thumbnails settings

$thumbnail-padding:                             .25rem !default;
$thumbnail-bg:                                  var(--#{$prefix}body-bg) !default;
$thumbnail-border-width:                        var(--#{$prefix}border-width) !default;
$thumbnail-border-color:                        var(--#{$prefix}border-color) !default;
$thumbnail-border-radius:                       var(--#{$prefix}border-radius) !default;
$thumbnail-box-shadow:                          var(--#{$prefix}box-shadow-sm) !default;



// 55. Figures settings

$figure-caption-font-size:                      $small-font-size !default;
$figure-caption-color:                          var(--#{$prefix}secondary-color) !default;



// 56. Carousel settings

$carousel-control-color:                        $white !default;
$carousel-control-width:                        15% !default;
$carousel-control-opacity:                      .5 !default;
$carousel-control-hover-opacity:                .9 !default;
$carousel-control-transition:                   opacity .15s ease !default;
$carousel-indicator-width:                      30px !default;
$carousel-indicator-height:                     3px !default;
$carousel-indicator-hit-area-height:            10px !default;
$carousel-indicator-spacer:                     3px !default;
$carousel-indicator-opacity:                    .5 !default;
$carousel-indicator-active-bg:                  $white !default;
$carousel-indicator-active-opacity:             1 !default;
$carousel-indicator-transition:                 opacity .6s ease !default;
$carousel-caption-width:                        70% !default;
$carousel-caption-color:                        $white !default;
$carousel-caption-padding-y:                    1.25rem !default;
$carousel-caption-spacer:                       1.25rem !default;
$carousel-control-icon-width:                   2rem !default;
$carousel-control-prev-icon-bg:                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
$carousel-control-next-icon-bg:                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;
$carousel-transition-duration:                  .6s !default;
$carousel-transition:                           transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
$carousel-dark-indicator-active-bg:             $black !default;
$carousel-dark-caption-color:                   $black !default;
$carousel-dark-control-icon-filter:             invert(1) grayscale(100) !default;



// 57. Spinners settings

$spinner-width:                                 2rem !default;
$spinner-height:                                $spinner-width !default;
$spinner-vertical-align:                        -.125em !default;
$spinner-border-width:                          .25em !default;
$spinner-animation-speed:                       .75s !default;
$spinner-width-sm:                              1rem !default;
$spinner-height-sm:                             $spinner-width-sm !default;
$spinner-border-width-sm:                       .2em !default;



// 58. Code settings

$code-font-size:                               $small-font-size !default;
$code-color:                                   $pink !default;
$kbd-padding-y:                                .1875rem !default;
$kbd-padding-x:                                .375rem !default;
$kbd-font-size:                                $code-font-size !default;
$kbd-color:                                    var(--#{$prefix}body-bg) !default;
$kbd-bg:                                       var(--#{$prefix}body-color) !default;
$pre-color:                                    null !default;