@mixin helper-color-dark($color, $value) {
	& .bg-gradient-#{$color} {
		background-color: $value !important;
		background-image: -moz-linear-gradient(top, lighten($value, 10%), darken($value, 5%)) !important;
		background-image: -webkit-linear-gradient(top, lighten($value, 10%), darken($value, 5%)) !important;
		background-image: linear-gradient(to bottom, lighten($value, 10%), darken($value, 5%)) !important;
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='lighten($value, 10%)', endColorstr='darken($value, 5%)',GradientType=0 ) !important;
	}
	& .border-#{$color} {
		@if ($color == 'theme') {
			border-color: var(--#{$prefix}theme) !important;
		}
	}
	& .theme-#{$color} {
		--#{$prefix}theme: #{$value} !important;
		--#{$prefix}theme-rgb: #{to-rgb($value)} !important;
		--#{$prefix}theme-color: #{color-contrast($value)} !important;
		--#{$prefix}theme-color-rgb: #{to-rgb(color-contrast($value))} !important;
	}
	@for $i from 1 through 4 {
		& .text-#{$color}-#{$i * 100} {
			--#{$prefix}text-opacity: 1;
			color: rgba(tint-color($value, (100 - ($i * 20)) * 1%), var(--#{$prefix}text-opacity)) !important;
		}
		.bg-#{$color}-#{$i * 100} {
			--#{$prefix}bg-opacity: 1;
			background-color: rgba(tint-color($value, (100 - ($i * 20)) * 1%), var(--#{$prefix}bg-opacity)) !important;
		}
		.text-#{$color}-#{($i + 5) * 100} {
			--#{$prefix}text-opacity: 1;
			color: rgba(shade-color($value, ($i * 20) * 1%), var(--#{$prefix}text-opacity))  !important;
		}
		.bg-#{$color}-#{($i + 5) * 100} {
			--#{$prefix}bg-opacity: 1;
			background-color: rgba(shade-color($value, ($i * 20) * 1%), var(--#{$prefix}bg-opacity)) !important;
		}
	}
}